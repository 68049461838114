import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IEquipamento } from '../../../Interfaces/IEquipamento';
import getProjetoEquipamentos from './Helpers/getProjetoEquipamentos';
import { toast } from 'react-toastify';
import Loading from '../Loading/Loading';
import ProjetoEquipamentosDataTable from './Components/ProjetoEquipamentosDataTable';

export interface ManageProjetoEquipamentosProps {}

const ManageProjetoEquipamentos: React.FC<ManageProjetoEquipamentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  // const onAddEquipamentoClick = (): void => {
  //   navigate(`/admin/adicionarProjetosEquipamento/${guid}`);
  // };

  const onBackToProjetos = (): void => {
    navigate('/admin/projetos');
  };

  const [equipamentos, setEquipamentos] = useState<IEquipamento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getProjetoEquipamentos(guid!)
      .then((data): void => {
        if (data.Success) {
          setEquipamentos(data.Object!);
        } else {
          toast.error('Erro ao buscar equipamentos do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar equipamentos do projeto');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar Equipamentos do Projeto"
      />
      <Box
        width="xlarge"
        align="end"
        direction="row"
        gap="xsmall"
        justify="end"
      >
        {/* <Button
          primary
          label="Adicionar Equipamento"
          title="Adicionar Equipamento"
          onClick={onAddEquipamentoClick}
          icon={<Add />}
          reverse
        /> */}
      </Box>

      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ProjetoEquipamentosDataTable
              refreshData={getData}
              equipamentos={equipamentos}
              completo
            />
          </Box>
        )
      }

      <Box
        width="xlarge"
        align="center"
        direction="row"
        gap="xsmall"
        justify="center"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious />}
          reverse
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetoEquipamentos.displayName = 'ManageProjetoEquipamentos';

export default ManageProjetoEquipamentos;
