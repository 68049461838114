import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import adicionarDocumentoUsuarioInitialValues from '../../Helpers/adicionarDocumentoInitialValues';
import adicionarDocumentoUsuarioSubmit from '../../Helpers/adicionarDocumentoUsuarioSubmit';

export interface AdicionarDocumentoUsuarioProps {}

const AdicionarDocumentoUsuario: React.FC<AdicionarDocumentoUsuarioProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onCancel = (): void => {
    navigate(`/owner/manageUsuariosDocumentos/${guid}`);
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento"
      />
      <Box
        width="large"
      >
        <DocumentosForm
          initialValues={adicionarDocumentoUsuarioInitialValues(guid!)}
          onSubmit={adicionarDocumentoUsuarioSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarDocumentoUsuario.displayName = 'AdicionarDocumentoUsuario';

export default AdicionarDocumentoUsuario;
