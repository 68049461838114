import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import Loading from '../Loading/Loading';
import getProjetoRelatorio from './Helpers/getProjetoRelatorio';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProjetoRelatorioVideosTable from './Components/ProjetoRelatorioVideosTable';
import ProjetoRelatorioDocumentosTable from './Components/ProjetoRelatorioDocumentosTable';
import { IProjetoDocumento } from '../../../Interfaces/IProjetoDocumento';
import { IProjeto } from '../../../Interfaces/IProjeto';
import ProjetoInfo from './Components/ProjetoInfo';
import { Box, Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';

export interface ManageProjetosRelatorioProps {}

const ManageProjetosRelatorio: React.FC<ManageProjetosRelatorioProps> = ({}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [relatorio, setRelatorio] = useState<IProjeto>({});

  const [imagens, setImagens] = useState<IProjetoDocumento[]>([]);
  const [rdo, setRdo] = useState<IProjetoDocumento[]>([]);
  const [relatorios, setRelatorios] = useState<IProjetoDocumento[]>([]);
  const [adicionais, setAdicionais] = useState<IProjetoDocumento[]>([]);

  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onVoltarClick = (): void => {
    navigate('/admin/projetos');
  };

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getProjetoRelatorio(guid!)
      .then((data): void => {
        if (data.Success) {
          const { Object } = data;
          setRelatorio(Object!);

          if (Object?.Documentos && Object.Documentos.length > 0) {
            const docImagens = Object.Documentos.filter((documento) => documento.Tipo?.Id === 2);
            setImagens(docImagens);

            const docRdo = Object.Documentos.filter((documento) => documento.Tipo?.Id === 4);
            setRdo(docRdo);

            const docRelatorios = Object.Documentos.filter((documento) => documento.Tipo?.Id === 3);
            setRelatorios(docRelatorios);

            const docAdicionais = Object.Documentos.filter((documento) => documento.Tipo?.Id === 1);
            setAdicionais(docAdicionais);
          }
        } else {
          toast.error('Erro ao buscar relatório do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar relatório do projeto');
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Relatório Final"
      />
      {
        (loading) ? (<Loading />) : (
          <>
            <ProjetoInfo projeto={relatorio} />

            <PageTitle
              Text="Dados do Projeto"
              level="3"
            />

            {(relatorio.Videos && relatorio.Videos.length > 0) && (
              <>
                <PageTitle
                  Text="Vídeos"
                  level="4"
                />
                <ProjetoRelatorioVideosTable
                  videos={relatorio.Videos}
                />
              </>
            )}

            {(imagens.length > 0) && (
              <>
                <PageTitle
                  Text="Imagens"
                  level="4"
                />
                <ProjetoRelatorioDocumentosTable
                  documentos={imagens}
                  refreshData={getInitialData}
                />
              </>
            )}

            {(rdo.length > 0) && (
              <>
                <PageTitle
                  Text="RDOs"
                  level="4"
                />
                <ProjetoRelatorioDocumentosTable
                  documentos={rdo}
                  refreshData={getInitialData}
                />
              </>
            )}

            {(relatorios.length > 0) && (
              <>
                <PageTitle
                  Text="Relatórios de Serviços"
                  level="4"
                />
                <ProjetoRelatorioDocumentosTable
                  documentos={relatorios}
                  refreshData={getInitialData}
                />
              </>
            )}

            {(adicionais.length > 0) && (
              <>
                <PageTitle
                  Text="Documentos Adicionais"
                  level="4"
                />
                <ProjetoRelatorioDocumentosTable
                  documentos={adicionais}
                  refreshData={getInitialData}
                />
              </>
            )}

            <Box
              justify="center"
              margin={{
                vertical: 'medium',
              }}
            >
              <Button
                label="Voltar"
                title="Voltar"
                onClick={onVoltarClick}
                icon={<LinkPrevious />}
                primary
              />
            </Box>
          </>
        )
      }
    </PageWrapper>
  );
};

ManageProjetosRelatorio.displayName = 'ManageProjetosRelatorio';

export default ManageProjetosRelatorio;
