import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import { Play } from 'grommet-icons';
import VideoLayer from '../../ManageVideos/Components/VideoLayer/VideoLayer';

export interface ProjetosRelatorioVideosActionsProps {
  video: IStreamingVideo;
}

const ProjetosRelatorioVideosActions: React.FC<ProjetosRelatorioVideosActionsProps> = ({ video }): JSX.Element => {
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const changeShowPlayer = (): void => setShowPlayer(!showPlayer);

  return (
    <Box
      direction="row"
      gap="none"
      pad="none"
    >
      <Button
        icon={<Play />}
        title='Visualizar Vídeo'
        onClick={changeShowPlayer}
      />
      {
        (showPlayer) && (
          <VideoLayer
            video={video}
            onCloseLayer={changeShowPlayer}
          />
        )
      }
    </Box>
  );
};

ProjetosRelatorioVideosActions.displayName = 'ProjetosRelatorioVideosActions';

export default ProjetosRelatorioVideosActions;
