import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStreamingVideo } from '../../../Interfaces/IStreamingVideo';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { Box, Button } from 'grommet';
import { Add, LinkPrevious } from 'grommet-icons';
import Loading from '../Loading/Loading';
import getVideosByProjeto from './Helpers/getVideosByProjeto';
import ManageVideosDataTable from '../ManageVideos/Components/ManageVideosDataTable';

export interface ManageProjetoVideosProps {}

const ManageProjetoVideos: React.FC<ManageProjetoVideosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddVideoClick = (): void => {
    navigate(`/admin/adicionarProjetosVideo/${guid}`);
  };

  const onBackToProjetos = (): void => {
    navigate('/admin/projetos');
  };

  const [videos, setVideos] = useState<IStreamingVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getVideosByProjeto(guid!)
      .then((data): void => {
        if (data.Success) {
          setVideos(data.Object!);
        } else {
          toast.error('Erro ao buscar vídeos do projeto');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar vídeos do projeto');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar Vídeos do Projeto"
      />
      <Box
        width="xlarge"
        align="end"
        direction="row"
        gap="xsmall"
        justify="end"
      >
        <Button
          primary
          label="Adicionar Vídeo"
          title="Adicionar Vídeo"
          onClick={onAddVideoClick}
          icon={<Add />}
          reverse
        />
      </Box>

      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ManageVideosDataTable
              videos={videos}
              refreshData={getData}
            />
          </Box>
        )
      }

      <Box
        width="xlarge"
        align="center"
        direction="row"
        gap="xsmall"
        justify="center"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious />}
          reverse
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetoVideos.displayName = 'ManageProjetoVideos';

export default ManageProjetoVideos;
