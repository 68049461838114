import { Route } from 'react-router-dom';
import IsAdminAuth from './IsAdminAuth';
import Error404 from '../../Error404/Error404';
import ManageProjetos from '../../ManageProjetos/ManageProjetos';
import AdicionarProjeto from '../../ManageProjetos/Adicionar/AdicionarProjeto';
import ManageProjetosDocumentos from '../../ManageProjetosDocumentos/ManageProjetosDocumentos';
import AdicionarDocumentoDoProjeto from '../../ManageProjetosDocumentos/Adicionar/AdicionarDocumentoDoProjeto';
import ManageProjetosRelatorio from '../../ManageProjetosRelatorio/ManageProjetosRelatorio';
import EditarProjeto from '../../ManageProjetos/Editar/EditarProjeto';
import EquipamentosPorEmpresa from '../../ManageEquipamentos/Components/EquipamentosPorEmpresa/EquipamentosPorEmpresa';
import AdicionarEquipamento from '../../ManageEquipamentos/Components/AdicionarEquipamento/AdicionarEquipamento';
import EditarEquipamento from '../../ManageEquipamentos/Components/EditarEquipamento/EditarEquipamento';
import ManageEquipamentosDocumentos from '../../ManageEquipamentosDocumentos/ManageEquipamentosDocumentos';
import AdicionarEquipamentoDocumento from '../../ManageEquipamentosDocumentos/Components/AdicionarDocumento/AdicionarEquipamentoDocumento';
import ManageProjetoEquipamentos from '../../ManageProjetoEquipamentos/ManageProjetoEquipamentos';
import AdicionarProjetoEquipamento from '../../ManageProjetoEquipamentos/Components/Adicionar/AdicionarProjetoEquipamento';
import ManageProjetoVideos from '../../ManageProjetoVideos/ManageProjetoVideos';
import AdicionarVideoAoProjeto from '../../ManageProjetoVideos/Components/Adicionar/AdicionarVideoAoProjeto';
import VideoUpload from '../../ManageVideos/Components/VideoUpload/VideoUpload';
import EditarVideoDoProjeto from '../../ManageProjetoVideos/Components/Editar/EditarVideoDoProjeto';


const useIsAdminRouter = (): JSX.Element => {
  return (
    <>
      <Route path="admin" element={<IsAdminAuth />} >
        {/* Projetos */}
        <Route path="projetos" element={<ManageProjetos />} />
        <Route path="projetosAdicionar" element={<AdicionarProjeto />} />
        <Route path="manageProjetosDocumentos/:guid" element={<ManageProjetosDocumentos />} />
        <Route path="adicionarDocumentoProjeto/:guid" element={<AdicionarDocumentoDoProjeto />} />
        <Route path="relatorioProjeto/:guid" element={<ManageProjetosRelatorio />} />
        <Route path="editarProjeto/:guid" element={<EditarProjeto />} />
        {/* /Projetos */}
        {/* Manage Projeto Equipamentos */}
        <Route path="manageProjetosEquipamentos/:guid" element={<ManageProjetoEquipamentos />} />
        <Route path="adicionarProjetosEquipamento/:guid" element={<AdicionarProjetoEquipamento />} />
        {/* /Manage Projeto Equipamentos */}
        {/* Manage Projeto Vídeos */}
        <Route path="manageProjetosVideos/:guid" element={<ManageProjetoVideos />} />
        <Route path="adicionarProjetosVideo/:guid" element={<AdicionarVideoAoProjeto />} />
        <Route path="editarProjetoVideo/:videoGuid/:projetoGuid" element={<EditarVideoDoProjeto />} />
        <Route path="videoUpload/:guid" element={<VideoUpload />} />
        {/* /Manage Projeto Vídeos */}
        {/* Manage Equipamentos */}
        <Route path="manageEquipamentos" element={<EquipamentosPorEmpresa />} />
        <Route path="adicionarEquipamento" element={<AdicionarEquipamento />} />
        <Route path="editarEquipamento/:guid" element={<EditarEquipamento />} />
        {/* /Manage Equipamentos */}
        {/* Manage Equipamento Documentos */}
        <Route path="manageEquipamentosDocumentos/:guid" element={<ManageEquipamentosDocumentos />} />
        <Route path="adicionarDocumentoEquipamento/:guid" element={<AdicionarEquipamentoDocumento />} />
        {/* /Manage Equipamento Documentos */}
        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsAdminRouter;
