import React, { useState } from 'react';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';
import ProjetosRelatorioVideosActions from './ProjetosRelatorioVideosActions';

export interface ProjetoRelatorioVideosTableProps {
  videos: IStreamingVideo[];
}

const ProjetoRelatorioVideosTable: React.FC<ProjetoRelatorioVideosTableProps> = ({ videos }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Title',
    direction: 'desc',
  });

  const columns: ColumnConfig<IStreamingVideo>[] = [
    {
      property: 'Title',
      primary: false,
      search: true,
      sortable: true,
      header: 'Título',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <Text>{datum.Title}</Text>
      ),
    }, {
      property: 'Description',
      primary: false,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <Text>{datum.Description}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ações',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <ProjetosRelatorioVideosActions
          video={datum}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={videos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ProjetoRelatorioVideosTable.displayName = 'ProjetoRelatorioVideosTable';

export default ProjetoRelatorioVideosTable;
