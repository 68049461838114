import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import { Briefcase, Edit, Sync, UserExpert, Validate } from 'grommet-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../Interfaces/IUser';
import onChangeActivePropertyForUser from '../Helpers/onChangeActivePropertyForUser';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import onChangeOwnerPropertyForUser from '../Helpers/onChangeOwnerPropertyForUser';
import onChangeTechnicianPropertyForUser from '../Helpers/onChangeTechnicianPropertyForUser';
import useAppContext from '../../Template/Context/useAppContext';
import changeUsers from '../Helpers/changeUsers';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import onChangeValidatedPropertyForUser from '../Helpers/onChangeValidatedPropertyForUser';

export interface ManageUsersActionsProps {
  user: IUser;
  refreshData: () => void;
}

const ManageUsersActions: React.FC<ManageUsersActionsProps> = ({ user, refreshData }): JSX.Element => {
  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const {
    state: { },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const changeActiveProp = async (): Promise<void> => {
    await onChangeActivePropertyForUser(user.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade ATIVO do usuário alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade ATIVO do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade ATIVO do usuário');
      });
  };

  const changeOwnerProp = async (): Promise<void> => {
    await onChangeOwnerPropertyForUser(user.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade OWNER do usuário alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade OWNER do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade OWNER do usuário');
      });
  };

  const changeTechnicianProp = async (): Promise<void> => {
    await onChangeTechnicianPropertyForUser(user.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade TÉCNICO do usuário alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade TÉCNICO do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade TÉCNICO do usuário');
      });
  };

  const changeValidatedProp = async (): Promise<void> => {
    await onChangeValidatedPropertyForUser(user.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade VALIDADO do usuário alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade VALIDADO do usuário');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade VALIDADO do usuário');
      });
  };

  const onChangeUsers = async (): Promise<void> => {
    await changeUsers(user.Guid!)
      .then((data): void => {
        if (data.Success) {
          const { Object: newUser } = data;
          toast.success('Login efetuado com sucesso');

          dispatch({
            type: 'changeAuth',
            value: true,
          });

          dispatch({
            type: 'changeDrawer',
            value: true,
          });

          dispatch({
            type: 'changeFirstName',
            value: newUser?.FirstName!,
          });

          dispatch({
            type: 'changeLastName',
            value: newUser?.LastName!,
          });

          dispatch({
            type: 'changeEmail',
            value: newUser?.Email!,
          });

          dispatch({
            type: 'changeOwner',
            value: newUser?.Owner || false,
          });

          dispatch({
            type: 'changeValidated',
            value: newUser?.Validated || false,
          });
        } else {
          toast.error('Erro ao alternar usuários. Provavelmente a conta está desativada');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alternar usuários. Provavelmente a conta está desativada');
      });
  };

  const onChangeOwnerClick = (): void => {
    setAction('changeOwner');
    changeShowModal();
  };

  const onChangeActiveClick = (): void => {
    setAction('changeActive');
    changeShowModal();
  };

  // const onChangeTechnicianClick = (): void => {
  //   setAction('changeTechnician');
  //   changeShowModal();
  // };

  const onSwitchUsersClick = (): void => {
    setAction('switchUsers');
    changeShowModal();
  };

  const onChangeValidatedClick = (): void => {
    setAction('changeValidated');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'changeActive':
        changeActiveProp();
        break;
      case 'changeOwner':
        changeOwnerProp();
        break;
      case 'changeTechnician':
        changeTechnicianProp();
        break;
      case 'changeValidated':
        changeValidatedProp();
        break;
      case 'switchUsers':
        onChangeUsers();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'changeActive':
        return 'Alterar propriedade ATIVO?';
      case 'changeOwner':
        return 'Alterar propriedade OWNER?';
      case 'changeTechnician':
        return 'Alterar propriedade TÉCNICO?';
      case 'changeValidated':
        return 'Alterar propriedade VALIDADO?';
      case 'switchUsers':
        return 'Alterar usuário?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'changeActive':
        return `O usuário ${user.FirstName} ${user.LastName} (${user.Email}) está ${user.Active ? 'ATIVO' : 'INATIVO'}. Deseja alterar?`;
      case 'changeOwner':
        return `O usuário ${user.FirstName} ${user.LastName} (${user.Email}) está ${user.Owner ? 'PROPRIETÁRIO' : 'COMUM'}. Deseja alterar?`;
      case 'changeTechnician':
        return `O usuário ${user.FirstName} ${user.LastName} (${user.Email}) está ${user.Technician ? 'TÉCNICO' : 'COMUM'}. Deseja alterar?`;
      case 'changeValidated':
        return `O usuário ${user.FirstName} ${user.LastName} (${user.Email}) está ${user.Validated ? 'VALIDADO' : 'NÃO VALIDADO'}. Deseja alterar?`;
      case 'switchUsers':
        return `Deseja alternar para o usuário ${user.FirstName} ${user.LastName} (${user.Email})? A conexão durará no máximo 20 minutos.`;
      default:
        return '';
    }
  };

  const onEditUserClick = (): void => {
    navigate(`/owner/editUser/${user.Guid}`);
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Edit />}
          title="Editar Usuário"
          onClick={onEditUserClick}
        />
        <Button
          icon={<Briefcase />}
          title="Alterar a propriedade 'OWNER' do usuário"
          onClick={onChangeOwnerClick}
        />
        {/* <Button
          icon={<Troubleshoot />}
          title="Alterar a propriedade 'TÉCNICO' do usuário"
          onClick={onChangeTechnicianClick}
        /> */}
        <Button
          icon={<UserExpert />}
          title="Alterar a propriedade 'ATIVO' do usuário"
          onClick={onChangeActiveClick}
        />
        <Button
          icon={<Validate />}
          title="Alterar a propriedade 'VALIDADO' do usuário"
          onClick={onChangeValidatedClick}
        />
        <Button
          icon={<Sync />}
          title="Alternar usuários"
          onClick={onSwitchUsersClick}
        />
        <ChangeEmail
          user={user}
          refreshData={refreshData}
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

ManageUsersActions.displayName = 'ManageUsersActions';

export default ManageUsersActions;
