import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Cluster,
  Configure,
  DocumentText, Group, Projects, UserExpert, UserSettings,
} from 'grommet-icons';
import { toast } from 'react-toastify';
import {
  AnchorInterdev, SectionSideBarContainer, TitleSectionSideBar,
} from './SideBarNavStyles';
import useAppContext from '../../../Context/useAppContext';
import onLogout from '../../../Header/Helpers/onLogout';

export interface SidebarNavProps { }

const SidebarNav: React.FC<SidebarNavProps> = ({ }): JSX.Element => {
  const size = useContext(ResponsiveContext);

  const {
    state: { Owner, Admin }, dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const onPermissoesClick = (): void => {
    navigate('/form/permissoes');
  };

  const onTestingErrorClick = (): void => {
    navigate('/owner/testingError');
  };

  // const onImportCsvClick = (): void => {
  //   navigate('/auth/importCsv');
  // };

  const onManageUsersClick = (): void => {
    navigate('/owner/manageUsers');
  };

  const onManageVideosClick = (): void => {
    navigate('/owner/manageVideos');
  };

  const onManageEquipamentosClick = (): void => {
    navigate('/owner/manageEquipamentos');
  };

  const onConclusaoClick = (): void => {
    navigate('/form/conclusao');
  };

  const onAddRelatorioClick = (): void => {
    navigate('/form/addrelatorio');
  };

  const onAddRdoClick = (): void => {
    navigate('/form/addrdo');
  };

  const onAcessosClick = (): void => {
    navigate('/form/acessos');
  };

  /* Gerenciamento */
  const onVencimentosClick = (): void => {
    navigate('/owner/vencimentos');
  };
  /* Gerenciamento */

  /* Empresas */
  const onManageEmpresasClick = (): void => {
    navigate('/owner/manageEmpresas');
  };
  
  const onAddEmpresaClick = (): void => {
    navigate('/owner/adicionarEmpresa');
  };
  /* /Empresas */

  /* Usuário */
  const onAlterarSenhaClick = (): void => {
    navigate('/two-factor/alterarSenha');
  };

  const onAlterarNomeClick = (): void => {
    navigate('/two-factor/alterarNome');
  };

  const onAlterarEmailClick = (): void => {
    navigate('/two-factor/alterarEmail');
  };

  const onAlterarAssinaturaClick = (): void => {
    navigate('/two-factor/alterarAssinatura');
  };

  const logoutUser = async (): Promise<void> => {
    await onLogout()
      .then((): void => {
        dispatch({
          type: 'changeAuth',
          value: false,
        });

        dispatch({
          type: 'changeDrawer',
          value: false,
        });

        dispatch({
          type: 'changeFirstName',
          value: '',
        });

        dispatch({
          type: 'changeLastName',
          value: '',
        });

        dispatch({
          type: 'changeEmail',
          value: '',
        });

        dispatch({
          type: 'changeOwner',
          value: false,
        });

        dispatch({
          type: 'changeValidated',
          value: false,
        });

        navigate('/');
      })
      .catch((): void => {
        toast.error('Erro ao sair do sistema');
        navigate('/');
      });
  };
  /* /Usuário */

  /* Projetos */
  const onProjetosCadastrarClick = (): void => {
    navigate('/admin/projetosAdicionar');
  };

  const onProjetosClick = (): void => {
    navigate('/admin/projetos');
  };
  /* /Projetos */

  /* Equipamentos */
  const onAdminEquipamentosClick = (): void => {
    navigate('/admin/manageEquipamentos');
  };
  const onAdminEquipamentosAddClick = (): void => {
    navigate('/admin/adicionarEquipamento');
  };
  /* /Equipamentos */

  return (
    <Box
      fill={size === 'small' ? 'horizontal' : 'vertical'}
      pad="medium"
      direction="column"
      elevation="large"
    >
      <SectionSideBarContainer>
        <UserSettings color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Menu</TitleSectionSideBar>
      </SectionSideBarContainer>
      <AnchorInterdev
        onClick={onAlterarAssinaturaClick}
        title="Alterar assinatura"
        label="Alterar assinatura"
      />
      <AnchorInterdev
        onClick={onAlterarEmailClick}
        title="Alterar email"
        label="Alterar email"
      />
      <AnchorInterdev
        onClick={onAlterarNomeClick}
        title="Alterar nome"
        label="Alterar nome"
      />
      <AnchorInterdev
        onClick={onAlterarSenhaClick}
        title="Alterar senha"
        label="Alterar senha"
      />
      <AnchorInterdev
        onClick={logoutUser}
        title="Sair"
        label="Sair"
      />
      <SectionSideBarContainer>
        <UserExpert color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Administração</TitleSectionSideBar>
      </SectionSideBarContainer>

      <AnchorInterdev
        // onClick={onCadastroProjetoClick}
        title="Minha conta"
        label="Minha conta"
      />

      {
        (Owner) && (
          <>
            <SectionSideBarContainer>
              <Group color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Clientes</TitleSectionSideBar>
            </SectionSideBarContainer>

            <AnchorInterdev
              onClick={onAddEmpresaClick}
              title="Cadastrar"
              label="Cadastrar"
            />

            <AnchorInterdev
              onClick={onManageEmpresasClick}
              title="Consultar"
              label="Consultar"
            />
          </>
        )
      }

      {
        (Admin) && (
          <>
            <SectionSideBarContainer>
              <Cluster color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Equipamentos</TitleSectionSideBar>
            </SectionSideBarContainer>
            <AnchorInterdev
              onClick={onAdminEquipamentosAddClick}
              title="Cadastrar"
              label="Cadastrar"
            />
            <AnchorInterdev
              onClick={onAdminEquipamentosClick}
              title="Consultar"
              label="Consultar"
            />
          </>
        )
      }

      <SectionSideBarContainer>
        <Projects color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Projetos</TitleSectionSideBar>
      </SectionSideBarContainer>

      <AnchorInterdev
        onClick={onProjetosCadastrarClick}
        title="Cadastrar"
        label="Cadastrar"
      />

      <AnchorInterdev
        onClick={onProjetosClick}
        title="Consultar"
        label="Consultar"
      />

      {
        // (IsAuthenticated) && (
        //   <>
        //     <SectionSideBarContainer>
        //       <DocumentText color="brand" size="16px" />
        //       <TitleSectionSideBar color="brand">Conectado***</TitleSectionSideBar>
        //     </SectionSideBarContainer>
        //     <AnchorInterdev
        //       onClick={onImportCsvClick}
        //       title="Import CSV"
        //       label="Import CSV"
        //     />
        //   </>
        // )
      }

      {
        (Owner) && (
          <>
            <SectionSideBarContainer>
              <Configure color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Gerenciamento</TitleSectionSideBar>
            </SectionSideBarContainer>
            <AnchorInterdev
              onClick={onManageEquipamentosClick}
              title="Equipamentos"
              label="Equipamentos"
            />

            <AnchorInterdev
              onClick={onVencimentosClick}
              title="Vencimentos"
              label="Vencimentos"
            />

            <SectionSideBarContainer>
              <DocumentText color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Owner***</TitleSectionSideBar>
            </SectionSideBarContainer>
            <AnchorInterdev
              onClick={onManageEmpresasClick}
              title="Gerenciar Empresas"
              label="Gerenciar Empresas"
            />
            <AnchorInterdev
              onClick={onManageEquipamentosClick}
              title="Gerenciar Equipamentos"
              label="Gerenciar Equipamentos"
            />
            <AnchorInterdev
              onClick={onManageUsersClick}
              title="Gerenciar Usuários"
              label="Gerenciar Usuários"
            />
            <AnchorInterdev
              onClick={onManageVideosClick}
              title="Gerenciar Vídeos"
              label="Gerenciar Vídeos"
            />
            <AnchorInterdev
              onClick={onTestingErrorClick}
              title="Testar Erros"
              label="Testar Erros"
            />

            <SectionSideBarContainer>
              <DocumentText color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Forms***</TitleSectionSideBar>
            </SectionSideBarContainer>

            <AnchorInterdev
              onClick={onPermissoesClick}
              title="Permissoes"
              label="Permissões"
            />
            <AnchorInterdev
              onClick={onConclusaoClick}
              title="Conclusao"
              label="Conclusao"
            />
            <AnchorInterdev
              onClick={onAddRelatorioClick}
              title="Add Relatório"
              label="Add Relatório"
            />
            <AnchorInterdev
              onClick={onAddRdoClick}
              title="Add RDO"
              label="Add RDO"
            />
            <AnchorInterdev
              onClick={onAcessosClick}
              title="Acessos"
              label="Acessos"
            />
          </>
        )
      }
    </Box>
  );
};

SidebarNav.displayName = 'SidebarNav';

export default SidebarNav;
