import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import { IVencimentos } from '../../../../Interfaces/IVencimentos';
import MyAxiosInstance from '../../../../utils/AxiosInstance';

const getVencimentos = async (): Promise<IBasicReturn<IVencimentos>> => {
  let vencimentos: IBasicReturn<IVencimentos> = {
    Success: true,
  };

  await MyAxiosInstance().get<IBasicReturn<IVencimentos>>('Empresa/Vencimentos')
    .then((response): IBasicReturn<IVencimentos> => {
      const { data } = response;

      vencimentos = data;

      return vencimentos;
    })
    .catch((): IBasicReturn<IVencimentos> => {
      vencimentos.Success = false;

      return vencimentos;
    });

  return vencimentos;
};

export default getVencimentos;
