import { Route } from 'react-router-dom';
import IsOwnerAuth from './IsOwnerAuth';
import ManageUsers from '../../ManageUsers/ManageUsers';
import TestingError from '../../TestingError/TestingError';
import Error404 from '../../Error404/Error404';
import EditUser from '../../ManageUsers/Components/EditUser/EditUser';
import ManageEmpresas from '../../ManageEmpresas/ManageEmpresas';
import AdicionarEmpresa from '../../ManageEmpresas/Components/AdicionarEmpresa/AdicionarEmpresa';
import ManageVideos from '../../ManageVideos/ManageVideos';
import VideoInsert from '../../ManageVideos/Components/VideoInsert/VideoInsert';
import VideoUpload from '../../ManageVideos/Components/VideoUpload/VideoUpload';
import VideoEdit from '../../ManageVideos/Components/VideoEdit/VideoEdit';
import EditarEmpresa from '../../ManageEmpresas/Components/EditarEmpresa/EditarEmpresa';
import InsertUsuario from '../../ManageUsers/Components/InsertUsuario/InsertUsuario';
import ManagePlantas from '../../ManagePlantas/ManagePlantas';
import AdicionarPlanta from '../../ManagePlantas/Components/AdicionarPlanta/AdicionarPlanta';
import ManageEmpresasDocumentos from '../../ManageEmpresasDocumentos/ManageEmpresasDocumentos';
import AdicionarDocumento from '../../ManageEmpresasDocumentos/Components/AdicionarDocumento/AdicionarDocumento';
import EditarPlanta from '../../ManagePlantas/Components/EditarPlanta/EditarPlanta';
import ManageEquipamentos from '../../ManageEquipamentos/ManageEquipamentos';
import AdicionarEquipamento from '../../ManageEquipamentos/Components/AdicionarEquipamento/AdicionarEquipamento';
import EditarDocumento from '../../ManageEmpresasDocumentos/Components/EditarDocumento/EditarDocumento';
import ManageUsuariosDocumentos from '../../ManageUsuariosDocumentos/ManageUsuariosDocumentos';
import AdicionarDocumentoUsuario from '../../ManageUsuariosDocumentos/Components/AdicionarDocumentoUsuario/AdicionarDocumentoUsuario';
import ManageEmpresasUsuarios from '../../ManageEmpresasUsuarios/ManageEmpresasUsuarios';
import EditarEquipamento from '../../ManageEquipamentos/Components/EditarEquipamento/EditarEquipamento';
import EditarUsuariosDocumentos from '../../ManageUsuariosDocumentos/Components/EditarUsuariosDocumentos/EditarUsuariosDocumentos';
import ManageEquipamentosDocumentos from '../../ManageEquipamentosDocumentos/ManageEquipamentosDocumentos';
import AdicionarEquipamentoDocumento from '../../ManageEquipamentosDocumentos/Components/AdicionarDocumento/AdicionarEquipamentoDocumento';
import Vencimentos from '../../Vencimentos/Vencimentos';

const useIsOwnerRouter = (): JSX.Element => {
  return (
    <>
      <Route path="owner" element={<IsOwnerAuth />} >
        <Route path="testingError" element={<TestingError />} />
        {/* Manage Users */}
        <Route path="manageUsers" element={<ManageUsers />} />
        <Route path="editUser/:guid" element={<EditUser />} />
        <Route path="inserirUsuario" element={<InsertUsuario />} />
        {/* /Manage Users */}
        {/* Manage Empresas */}
        <Route path="manageEmpresas" element={<ManageEmpresas />} />
        <Route path="adicionarEmpresa" element={<AdicionarEmpresa />} />
        <Route path="editarEmpresa/:guid" element={<EditarEmpresa />} />
        {/* /Manage Empresas */}
        {/* Manage Videos */}
        <Route path="manageVideos" element={<ManageVideos />} />
        <Route path="videoInsert" element={<VideoInsert />} />
        <Route path="videoUpload/:guid" element={<VideoUpload />} />
        <Route path="videoEdit/:guid" element={<VideoEdit />} />
        {/* /Manage Videos */}
        {/* Manage Plantas */}
        <Route path="managePlantas/:guid" element={<ManagePlantas />} />
        <Route path="adicionarPlanta/:guid" element={<AdicionarPlanta />} />
        <Route path="editarPlanta/:guid" element={<EditarPlanta />} />
        {/* /Manage Plantas */}
        {/* Manage Empresa Documentos */}
        <Route path="manageEmpresaDocumentos/:guid" element={<ManageEmpresasDocumentos />} />
        <Route path="adicionarDocumento/:guid" element={<AdicionarDocumento />} />
        <Route path="editarDocumento/:guid" element={<EditarDocumento />} />
        {/* /Manage Empresa Documentos */}
        {/* Manage Empresas Usuarios */}
        <Route path="manageEmpresasUsuarios/:guid" element={<ManageEmpresasUsuarios />} />
        {/* Manage Empresas Usuarios */}
        {/* /Manage Empresas Usuarios */}
        {/* Manage Usuario Documentos */}
        <Route path="manageUsuariosDocumentos/:guid" element={<ManageUsuariosDocumentos />} />
        <Route path="adicionarDocumentoUsuario/:guid" element={<AdicionarDocumentoUsuario />} />
        <Route path="EditarUsuariosDocumentos/:guid" element={<EditarUsuariosDocumentos />} />
        {/* /Manage Usuario Documentos */}
        {/* Manage Equipamentos */}
        <Route path="manageEquipamentos" element={<ManageEquipamentos />} />
        <Route path="adicionarEquipamento" element={<AdicionarEquipamento />} />
        <Route path="editarEquipamento/:guid" element={<EditarEquipamento />} />
        {/* /Manage Equipamentos */}
        {/* /Manage Equipamentos */}
        {/* Manage Usuários Por Empresas */}
        <Route path="manageEmpresaUsuarios/:guid" element={<ManageEmpresasUsuarios />} />
        {/* /Manage Usuários Por Empresas */}
        {/* Manage Equipamento Documentos */}
        <Route path="manageEquipamentosDocumentos/:guid" element={<ManageEquipamentosDocumentos />} />
        <Route path="adicionarDocumentoEquipamento/:guid" element={<AdicionarEquipamentoDocumento />} />
        {/* /Manage Equipamento Documentos */}
        <Route path="vencimentos" element={<Vencimentos />} />

        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsOwnerRouter;
