import { IProjeto } from '../../../../Interfaces/IProjeto';

const adicionarProjetoInitialValues = (): IProjeto => {
  const dataAtual = new Date();
  const dataFutura = new Date();
  dataFutura.setDate(dataFutura.getDate() + 1);
  
  return {
    Titulo: '',
    Numero: '',
    Escopo: '',
    OnShore: true,
    EmpresaGuid: '',
    PlantaGuid: '',
    Previsao: 1,
    PrevisaoUnidade: 'Dias',
    PrevisaoInicio: dataAtual.toISOString(),
    PrevisaoTermino: dataFutura.toISOString(),
    LocalAtendimento: '',
    Observacoes: '',
    EquipamentoGuid: [],
  };
};

export default adicionarProjetoInitialValues;
