import { Route } from 'react-router-dom';
import IsAuthenticatedAuth from './IsAuthenticatedAuth';
import ImportCsv from '../../ImportCsv/ImportCsv';
import Error404 from '../../Error404/Error404';
import ManageEmpresasUsuarios from '../../ManageEmpresasUsuarios/ManageEmpresasUsuarios';

const useIsAuthenticatedRouter = (): JSX.Element => {
  return (
    <>
      <Route path="auth" element={<IsAuthenticatedAuth />} >
        <Route path="importCsv" element={<ImportCsv />} />
        {/* Empresas */}
        <Route path="manageEmpresasUsuarios" element={<ManageEmpresasUsuarios />} />
        {/* /Empresas */}
        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsAuthenticatedRouter;
