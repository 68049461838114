import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor, Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import useAppContext from '../../Template/Context/useAppContext';

export interface ManageEquipamentosTotalDocumentosProps {
  equipamento: IEquipamento;
}

const ManageEquipamentosTotalDocumentos: React.FC<ManageEquipamentosTotalDocumentosProps> = ({ equipamento }): JSX.Element => {
  const {
    state: { Admin },
  } = useAppContext();

  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    if (Admin) {
      navigate(`/admin/manageEquipamentosDocumentos/${equipamento.Guid}`);
    } else {
      navigate(`/owner/manageEquipamentosDocumentos/${equipamento.Guid}`);
    }
  };

  const onNavigateToAddDocumento = (): void => {
    if (Admin) {
      navigate(`/admin/adicionarDocumentoEquipamento/${equipamento.Guid}`);
    } else {
      navigate(`/owner/adicionarDocumentoEquipamento/${equipamento.Guid}`);
    }
  };

  return (
    <Box
      direction="row"
      gap="xmall"
      align="center"
    >
      <Anchor
        title="Clique para ver os documentos da empresa"
        onClick={onDocumentosClick}
      >
        {equipamento.TotalDocumentos || 0}
      </Anchor>
      <Button
        title="Adicionar documento"
        icon={<Add size="16" />}
        onClick={onNavigateToAddDocumento}
      />
    </Box>
  );
};

ManageEquipamentosTotalDocumentos.displayName = 'ManageEquipamentosTotalDocumentos';

export default ManageEquipamentosTotalDocumentos;
