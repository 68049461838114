import React, { useState } from 'react';
import { IEmpresaDocumento } from '../../../../../Interfaces/IEmpresaDocumento';
import { IDataTableSort } from '../../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';

export interface VencimentosDocumentosEmpresaProps {
  documentos: IEmpresaDocumento[];
}

const VencimentosDocumentosEmpresa: React.FC<VencimentosDocumentosEmpresaProps> = ({ documentos }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'DataVencimento',
    direction: 'desc',
  });

  const columns: ColumnConfig<IEmpresaDocumento>[] = [
    {
      property: 'Nome',
      primary: false,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IEmpresaDocumento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Descricao',
      primary: false,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IEmpresaDocumento): React.ReactNode => (
        <Text>{datum.Descricao}</Text>
      ),
    },
    {
      property: 'DataVencimento',
      primary: false,
      search: false,
      sortable: true,
      header: 'Data de Vencimento',
      render: (datum: IEmpresaDocumento): React.ReactNode => (
        <Text>{datum.DataVencimento}</Text>
      ),
    }, {
      property: 'DiasParaVencimento',
      primary: false,
      search: false,
      sortable: true,
      header: 'Dias até o vencimento',
      render: (datum: IEmpresaDocumento): React.ReactNode => (
        <Text>{datum.DiasParaVencimento || 0}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEmpresaDocumento): React.ReactNode => (
        <>{ datum.DiasParaVencimento }</>
        // <ManageEmpresasDocumentosActions
        //   documento={datum}
        //   refreshData={refreshData}
        // />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={documentos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

VencimentosDocumentosEmpresa.displayName = 'VencimentosDocumentosEmpresa';

export default VencimentosDocumentosEmpresa;
