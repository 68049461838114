import React, { useState } from 'react';
import { Box, Button, Text } from 'grommet';
import { PlayFill } from 'grommet-icons';
import { IProjeto } from '../../../../../Interfaces/IProjeto';
import projetoIniciar from '../../Helpers/projetoIniciar';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';

export interface ProjetoIniciarProps {
  projeto: IProjeto;
  refreshData: () => void;
}

const ProjetoIniciar: React.FC<ProjetoIniciarProps> = ({ projeto, refreshData }): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const changeShowModal = (): void => setShowModal(!showModal);

  const onIniciarProjetoClick = (): void => {
    changeShowModal();
  };

  const onStartProjeto = async (): Promise<void> => {
    setDisabled(true);

    await projetoIniciar(projeto.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto iniciado com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao iniciar projeto');
        }
        setDisabled(false);
      })
      .catch((): void => {
        toast.error('Erro ao iniciar projeto');
        setDisabled(false);
      });
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
        align="center"
      >
        <Text>Não iniciado</Text>
        <Button
          icon={<PlayFill />}
          title='Iniciar Projeto'
          onClick={onIniciarProjetoClick}
          disabled={disabled}
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title="Iniciar Projeto"
            text="Deseja realmente iniciar o projeto?"
            onCancel={changeShowModal}
            onConfirm={onStartProjeto}
          />
        )
      }
    </>
  );
};

ProjetoIniciar.displayName = 'ProjetoIniciar';

export default ProjetoIniciar;
