import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import { Anchor, Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

export interface ManageProjetosTotalDocumentosProps {
  projeto: IProjeto;
}

const ManageProjetosTotalDocumentos: React.FC<ManageProjetosTotalDocumentosProps> = ({ projeto }): JSX.Element => {
  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    navigate(`/admin/manageProjetosDocumentos/${projeto.Guid}`);
  };

  const onNavigateToAddDocumento = (): void => {
    navigate(`/admin/adicionarDocumentoProjeto/${projeto.Guid}`);
  };

  return (
    <Box
      direction="row"
      gap="xmall"
      align="center"
    >
      <Anchor
        title="Clique para ver os documentos do projeto"
        onClick={onDocumentosClick}
      >
        {projeto.TotalDocumentos || 0}
      </Anchor>
      <Button
        title="Adicionar documento"
        icon={<Add size="16" />}
        onClick={onNavigateToAddDocumento}
      />
    </Box>
  );
};

ManageProjetosTotalDocumentos.displayName = 'ManageProjetosTotalDocumentos';

export default ManageProjetosTotalDocumentos;
