import React from 'react';
import { DocumentImage, DocumentPdf } from 'grommet-icons';
import { Button } from 'grommet';
import { IEquipamentoDocumento } from '../../../../Interfaces/IEquipamentoDocumento';

export interface EquipamentosDocumentoDownloadProps {
  documento: IEquipamentoDocumento;
}

const EquipamentosDocumentoDownload: React.FC<EquipamentosDocumentoDownloadProps> = ({ documento }): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (documento.Upload?.UploadMimeType === 'application/pdf') {
      return (
        <DocumentPdf />
      );
    }

    return (
      <DocumentImage />
    );
  };

  const onClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoEquipamento/${documento.Guid}.${documento.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <Button
      icon={<Icon />}
      onClick={onClick}
    />
  );
};

EquipamentosDocumentoDownload.displayName = 'EquipamentosDocumentoDownload';

export default EquipamentosDocumentoDownload;
