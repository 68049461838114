import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from 'grommet';
import { Trash } from 'grommet-icons';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import projetoEquipamentoExcluir from '../Helpers/projetoEquipamentoExcluir';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export interface ProjetoEquipamentosActionsProps {
  equipamento: IEquipamento;
  refreshData: () => void;
}

const ProjetoEquipamentosActions: React.FC<ProjetoEquipamentosActionsProps> = ({ equipamento, refreshData }): JSX.Element => {
  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'changeActive':
        return 'Alterar propriedade ATIVO?';
      case 'delete':
        return 'Excluir documento?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'changeActive':
        return `O equipamenmento ${equipamento.Nome} está ${equipamento.Ativo ? 'ATIVO' : 'INATIVO'}. Deseja alterar?`;
      case 'delete':
        return `Deseja remover o equipamento ${equipamento.Nome} do projeto?`;
      default:
        return '';
    }
  };

  const onDeleteEquipamento = async (): Promise<void> => {
    await projetoEquipamentoExcluir(equipamento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Documento excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o documento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o documento');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'changeActive':
        // changeActiveProp();
        break;
      case 'delete':
        onDeleteEquipamento();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Trash />}
          onClick={onDeleteClick}
          title="Excluir equipamento"
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

ProjetoEquipamentosActions.displayName = 'ProjetoEquipamentosActions';

export default ProjetoEquipamentosActions;
