import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEquipamentoDocumento } from '../../../../Interfaces/IEquipamentoDocumento';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import EquipamentosDocumentosActions from './EquipamentosDocumentosActions';

export interface EquipamentosDocumentosTableProps {
  documentos: IEquipamentoDocumento[];
  refreshData: () => void;
}

const EquipamentosDocumentosTable: React.FC<EquipamentosDocumentosTableProps> = ({ documentos, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEquipamentoDocumento>[] = [
    {
      property: 'Nome',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Descricao',
      primary: true,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Descricao}</Text>
      ),
    },
    {
      property: 'Ativo',
      primary: true,
      search: false,
      sortable: true,
      header: 'Ativo',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Ativo ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'DataVencimento',
      primary: true,
      search: false,
      sortable: true,
      header: 'Vencimento?',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <Text>{datum.Vencimento ? datum.DataVencimento : '---'}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEquipamentoDocumento): React.ReactNode => (
        <EquipamentosDocumentosActions
          documento={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={documentos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

EquipamentosDocumentosTable.displayName = 'EquipamentosDocumentosTable';

export default EquipamentosDocumentosTable;
