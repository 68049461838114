import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import CriadoPor from '../../CriadoPor/CriadoPor';
import ProjetoEquipamentosActions from './ProjetoEquipamentosActions';

export interface ProjetoEquipamentosDataTableProps {
  equipamentos: IEquipamento[];
  refreshData: () => void;
  completo?: boolean;
}

const ProjetoEquipamentosDataTable: React.FC<ProjetoEquipamentosDataTableProps> = ({ equipamentos, refreshData, completo = false }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEquipamento>[] = [
    {
      property: 'Nome',
      primary: true,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Created',
      primary: true,
      search: true,
      sortable: true,
      header: 'Adicionado',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Created}</Text>
      ),
    },
    {
      property: 'CreatedBy',
      primary: true,
      search: true,
      sortable: true,
      header: 'Adicionado por',
      render: (datum: IEquipamento): React.ReactNode => (
        <CriadoPor
          criadoPor={datum.CreatedBy!}
        />
      ),
    },
    {
      property: 'Excluded',
      primary: true,
      search: true,
      sortable: true,
      header: 'Excluído',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{(datum.Excluded) ? datum.ExcludedWhen : '---'}</Text>
      ),
    },
    {
      property: 'ExcludedBy',
      primary: true,
      search: true,
      sortable: true,
      header: 'Excluído por',
      render: (datum: IEquipamento): React.ReactNode => (
        (datum.Excluded) ? (<CriadoPor criadoPor={datum.CreatedBy!} />) : (<Text>---</Text>)
      ),
    },
  ];

  if (completo) {
    columns.push({
      property: 'Guid',
      primary: false,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEquipamento): React.ReactNode => (
        <ProjetoEquipamentosActions
          equipamento={datum}
          refreshData={refreshData}
        />
      ),
    });
  }

  return (
    <DataTable
      fill="horizontal"
      data={equipamentos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ProjetoEquipamentosDataTable.displayName = 'ProjetoEquipamentosDataTable';

export default ProjetoEquipamentosDataTable;
