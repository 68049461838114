import { Box, Button, Spinner } from 'grommet';
import { Edit, Play, Trash } from 'grommet-icons';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import VideoLayer from './VideoLayer/VideoLayer';
import deleteVideo from '../Helpers/deleteVideo';
import { toast } from 'react-toastify';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export interface ManageVideosActionsProps {
  video: IStreamingVideo;
  refreshData: () => void;
}

const ManageVideosActions: React.FC<ManageVideosActionsProps> = ({ video, refreshData }): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onVideoEditClick = (): void => {
    navigate(`/admin/editarProjetoVideo/${video.Guid}/${guid}`);
  };

  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const changeShowPlayer = (): void => setShowPlayer(!showPlayer);

  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const onDeleteVideo = async (): Promise<void> => {
    await deleteVideo(video.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Vídeo excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o vídeo');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o vídeo');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'delete':
        onDeleteVideo();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'delete':
        return 'Excluir Vídeo?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'delete':
        return 'Esta operação é irreversível. Deseja realmente excluir o vídeo?';
      default:
        return '';
    }
  };

  return (
    <Box
      direction="row"
      gap="none"
      pad="none"
    >
      <Button
        icon={<Edit />}
        title='Editar Vídeo'
        onClick={onVideoEditClick}
      />
      <Button
        icon={<Trash />}
        title='Excluir Vídeo'
        onClick={onDeleteClick}
      />
      {/* <Button
        icon={<Upload />}
        title="Upload de vídeo"
        onClick={onUploadVideoClick}
      /> */}
      {
        (video.Encoded) ? (
          <Button
            icon={<Play />}
            title='Visualizar Vídeo'
            onClick={changeShowPlayer}
          />
        ) : (
        <Box
          title="Em processamento"
          pad={{
            horizontal: 'small',
            vertical: 'xxsmall',
          }}
        >
          <Spinner />
        </Box>
        )
      }
      {
        (showPlayer) && (
          <VideoLayer
            video={video}
            onCloseLayer={changeShowPlayer}
          />
        )
      }
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </Box>
  );
};

ManageVideosActions.displayName = 'ManageVideosActions';

export default ManageVideosActions;
