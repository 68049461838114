import React from 'react';
import { IUser } from '../../../Interfaces/IUser';
import { Box, Button, Text, Tip } from 'grommet';
import { CircleInformation } from 'grommet-icons';

export interface CriadoPorProps {
  criadoPor: IUser;
}

const CriadoPor: React.FC<CriadoPorProps> = ({ criadoPor }): JSX.Element => {
  return (
    <Tip
      // dropProps={{ align: { left: 'right' } }}
      content={
        <Box
          pad="xsmall"
          gap="xsmall"
          background={{ color: 'background-front', opacity: 'strong' }}
        >
          <Text weight="bold">User Info:</Text>
          <>
            <Text size="small">
              {criadoPor.FirstName} {criadoPor.LastName}
            </Text>
            <Text size="small">{criadoPor.Email}</Text>
          </>
        </Box>
      }
    >
      <Box
        direction="row"
        align="center"
      >
        <Text>{criadoPor.FirstName}</Text>
        <Button
          icon={<CircleInformation />}
          title="User Info"
        />
      </Box>
    </Tip>
  );
};

CriadoPor.displayName = 'CriadoPor';

export default CriadoPor;
