import React, { useState } from 'react';
import { Box, Button } from 'grommet';
import { Edit, Trash, Checkmark } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import { IPlanta } from '../../../../Interfaces/IPlanta';
import deletePlanta from '../Helpers/deletePlanta';
import { toast } from 'react-toastify';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import onChangeActivePropertyForPlanta from '../Helpers/onChangeActivePropertyForPlanta';

export interface ManagePlantasActionsProps {
  planta: IPlanta;
  refreshData: () => void;
}

const ManagePlantasActions: React.FC<ManagePlantasActionsProps> = ({ planta, refreshData }): JSX.Element => {
  const navigate = useNavigate();

  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };

  const onEditPlantaClick = (): void => {
    navigate(`/owner/editarPlanta/${planta.Guid}`);
  };

  const onDeletePlanta = async (): Promise<void> => {
    await deletePlanta(planta.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Planta excluída com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir a planta');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir a planta');
      });
  };

  const onChangeActivePlanta = async (): Promise<void> => {
    await onChangeActivePropertyForPlanta(planta.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Status da planta alterado com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar o status da planta');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar o status da planta');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onChangeActiveClick = (): void => {
    setAction('changeActive');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'delete':
        onDeletePlanta();
        break;
      case 'changeActive':
        onChangeActivePlanta();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'delete':
        return 'Excluir Planta?';
      case 'changeActive':
        return 'Alterar status da Planta?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'delete':
        return 'Esta operação é irreversível. Deseja realmente excluir a planta?';
      case 'changeActive':
        return `A planta "${planta.Nome}" está atualmente ${planta.Ativo ? 'ATIVA' : 'INATIVA'}. Deseja alterar o status?`;
      default:
        return '';
    }
  };

  return (
    <>
      <Box direction="row" gap="none" pad="none">
        <Button icon={<Edit />} onClick={onEditPlantaClick} title="Editar planta" />
        <Button icon={<Trash />} onClick={onDeleteClick} title="Excluir planta" />
        <Button icon={<Checkmark />} onClick={onChangeActiveClick} title="Alterar status da planta" />
      </Box>
      {showModal && (
        <ConfirmModal
          title={confirmModalTitle()}
          text={confirmModalText()}
          onCancel={changeShowModal}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

ManagePlantasActions.displayName = 'ManagePlantasActions';

export default ManagePlantasActions;
