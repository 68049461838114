import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Edit, Notes, Trash } from 'grommet-icons';
import { toast } from 'react-toastify';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import projetoExcluir from '../Helpers/projetoExcluir';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export interface ManageProjetosActionsProps {
  projeto: IProjeto;
  refreshData: () => void;
}

const ManageProjetosActions: React.FC<ManageProjetosActionsProps> = ({ projeto, refreshData }): JSX.Element => {
  const navigate = useNavigate();

  const onEditProjetoClick = (): void => {
    navigate(`/admin/editarProjeto/${projeto.Guid}`);
  };

  const onProjetoConsolidadoClick = (): void => {
    navigate(`/admin/relatorioProjeto/${projeto.Guid}`);
  };

  const [showExcluir, setShowExcluir] = React.useState<boolean>(false);
  const invertChangeExcluir = (): void => setShowExcluir(!showExcluir);

  const onExcluirProjetoClick = (): void => {
    invertChangeExcluir();
  };

  const onExcluirProjeto = async (): Promise<void> => {
    await projetoExcluir(projeto.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Projeto excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir projeto');
        }
        invertChangeExcluir();
      })
      .catch((): void => {
        toast.error('Erro ao excluir projeto');
        invertChangeExcluir();
      });
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Edit />}
          title='Editar Projeto'
          onClick={onEditProjetoClick}
        />
        <Button
          icon={<Trash />}
          title='Excluir Projeto'
          onClick={onExcluirProjetoClick}
        />
        <Button
          icon={<Notes />}
          title='Documentos consolidados'
          onClick={onProjetoConsolidadoClick}
        />
        {
          (showExcluir) && (
            <ConfirmModal
              title="Excluir Projeto"
              text="Esta ação excluirá definitivamente o projeto e seus documentos. Deseja continuar?"
              onConfirm={onExcluirProjeto}
              onCancel={invertChangeExcluir}
            />
          )
        }
      </Box>
    </>
  );
};

ManageProjetosActions.displayName = 'ManageProjetosActions';

export default ManageProjetosActions;
