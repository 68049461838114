import React from 'react';
import { Box, List, NameValueList, NameValuePair, Text } from 'grommet';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import CriadoPor from '../../CriadoPor/CriadoPor';

export interface ProjetoInfoProps {
  projeto: IProjeto;
}

const ProjetoInfo: React.FC<ProjetoInfoProps> = ({ projeto }): JSX.Element => {
  const textForStatus = (status: number = 0): string => {
    switch (status) {
      case 1:
        return 'Nâo iniciado';
      case 2:
        return 'Iniciado';
      case 3:
        return 'Finalizado';
      default:
        return 'Não iniciado';
    }
  };

  return (
    <Box
      width="large"
      margin={{
        top: 'medium',
        bottom: 'medium',
      }}
    >
      <NameValueList>
        <NameValuePair key="Título" name="Título do Projeto">
          {projeto.Titulo}
        </NameValuePair>
        <NameValuePair key="Número" name="Número do Projeto">
          {projeto.Numero}
        </NameValuePair>
        <NameValuePair key="Escopo" name="Escopo">
          {projeto.Escopo}
        </NameValuePair>
        <NameValuePair key="Planta" name="Empresa / Planta">
          {`${projeto.Empresa?.NomeFantasia} / ${projeto.Planta?.Nome}`}
        </NameValuePair>
        <NameValuePair key="Status" name="Status">
          {textForStatus(projeto.Status)}
        </NameValuePair>
        <NameValuePair key="PrevisãoInicio" name="Previsão de Início">
          {projeto.PrevisaoInicio}
        </NameValuePair>
        <NameValuePair key="PrevisãoTermino" name="Previsão de Término">
          {projeto.PrevisaoTermino}
        </NameValuePair>
        {
          (projeto.Status === 2 || projeto.Status === 3) && (
            <NameValuePair key="Início" name="Início">
              <Box
                direction="row"
                align="center"
                gap="xxsmall"
              >
                <Text>{`Iniciado em ${projeto.Inicio} por`}</Text>
                <CriadoPor criadoPor={projeto.IniciadoPor!} />
              </Box>
            </NameValuePair>
          )
        }
        {
          (projeto.Status === 3) && (
            <NameValuePair key="Término" name="Término">
              <Box
                direction="row"
                align="center"
                gap="xxsmall"
              >
                <Text>{`Terminado em ${projeto.Termino} por`}</Text>
                <CriadoPor criadoPor={projeto.TerminadoPor!} />
              </Box>
            </NameValuePair>
          )
        }
        {
          (projeto.Observacoes && projeto.Observacoes.length > 0) && (
            <NameValuePair key="Observações" name="Observações">
              {projeto.Observacoes}
            </NameValuePair>
          )
        }
        {
          (projeto.Equipamentos && projeto.Equipamentos.length > 0) && (
            <NameValuePair key="Equipamentos" name="Equipamentos">
              <List
                primaryKey="Nome"
                data={projeto.Equipamentos}
              />
            </NameValuePair>
          )
        }
      </NameValueList>
    </Box>
  );
};

ProjetoInfo.displayName = 'ProjetoInfo';

export default ProjetoInfo;
