import React, { useState } from 'react';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import { ColumnConfig, DataTable, Text } from 'grommet';
import ManageProjetosActions from './ManageProjetosActions';
import ProjetoIniciar from './ProjetoStatus/ProjetoIniciar';
import ProjetoFinalizar from './ProjetoStatus/ProjetoFinalizar';
import ManageProjetosTotalDocumentos from './ManageProjetosTotalDocumentos';
import ManageProjetosDataPrevista from './ManageProjetosDataPrevista';
import ManageProjetosTotalEquipamentos from './ManageProjetosTotalEquipamentos';
import ManageProjetosTotalVideos from './ManageProjetosTotalVideos';

export interface ManageProjetosDataTableProps {
  projetos: IProjeto[];
  refreshData: () => void;
}

const ManageProjetosDataTable: React.FC<ManageProjetosDataTableProps> = ({ projetos, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Status',
    direction: 'asc',
  });

  // const statusLabel = (status: number): string => {
  //   switch (status) {
  //     case 1:
  //       return 'Não iniciado';
  //     case 2:
  //       return 'Iniciado';
  //     case 3:
  //       return 'Concluído';
  //     default:
  //       return '';
  //   }
  // };

  const columns: ColumnConfig<IProjeto>[] = [
    {
      property: 'Titulo',
      primary: true,
      search: false,
      sortable: true,
      header: 'Título',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Titulo}</Text>
      ),
    },
    {
      property: 'Status',
      primary: false,
      search: false,
      sortable: true,
      header: 'Status',
      render: (datum: IProjeto): React.ReactNode => (
        <>
          {
            (datum.Status === 1) && (
              <ProjetoIniciar
                projeto={datum}
                refreshData={refreshData}
              />
            )
          }
          {
            (datum.Status === 2) && (
              <ProjetoFinalizar
                projeto={datum}
                refreshData={refreshData}
              />
            )
          }
          {
            (datum.Status === 3) && (
              <Text>Finalizado</Text>
            )
          }
        </>
      ),
    },
    {
      property: 'Inicio',
      primary: false,
      search: false,
      sortable: true,
      header: 'Início',
      render: (datum: IProjeto): React.ReactNode => (
        (datum.Status === 1)
          ? (<ManageProjetosDataPrevista data={datum.PrevisaoInicio!} />)
          : (<Text truncate>{datum.Inicio}</Text>)
      ),
    },
    {
      property: 'Termino',
      primary: false,
      search: false,
      sortable: true,
      header: 'Término',
      render: (datum: IProjeto): React.ReactNode => (
        (datum.Status !== 3)
          ? (<ManageProjetosDataPrevista data={datum.PrevisaoTermino!} />)
          : (<Text truncate>{datum.Termino}</Text>)
      ),
    },
    {
      property: 'Empresa.NomeFantasia',
      primary: false,
      search: true,
      sortable: true,
      header: 'Empresa',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Empresa?.NomeFantasia}</Text>
      ),
    },
    {
      property: 'Planta.Nome',
      primary: false,
      search: true,
      sortable: true,
      header: 'Planta',
      render: (datum: IProjeto): React.ReactNode => (
        <Text>{datum.Planta?.Nome}</Text>
      ),
    },
    {
      property: 'TotalDocumentos',
      primary: true,
      search: false,
      sortable: true,
      header: 'Documentos',
      render: (datum: IProjeto): React.ReactNode => (
        <ManageProjetosTotalDocumentos
          projeto={datum}
        />
      ),
    },
    {
      property: 'TotalEquipamentos',
      primary: true,
      search: false,
      sortable: true,
      header: 'Equipamentos',
      render: (datum: IProjeto): React.ReactNode => (
        <ManageProjetosTotalEquipamentos
          projeto={datum}
        />
      ),
    },
    {
      property: 'TotalVídeos',
      primary: true,
      search: false,
      sortable: true,
      header: 'Vídeos',
      render: (datum: IProjeto): React.ReactNode => (
        <ManageProjetosTotalVideos
          projeto={datum}
        />
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IProjeto): React.ReactNode => (
        <ManageProjetosActions
          projeto={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={projetos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageProjetosDataTable.displayName = 'ManageProjetosDataTable';

export default ManageProjetosDataTable;
