import {
  Anchor, Box, Image, ResponsiveContext, Text,
} from 'grommet';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentContainer, Description, Divider, InfoContainer, Title } from './AppFooterStyles';
import PageWrapper from '../../PageWrapper/PageWrapper';

export interface AppFooterProps {}

const AppFooter: React.FC<AppFooterProps> = (): JSX.Element => {
  const { t } = useTranslation(['footer']);

  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();

  const onLogoClick = (): void => {
    navigate('/');
  };
  return (
    <Box
      gridArea="footer"
      background="secondary"
      justify="center"
      align="center"
      pad="large"
      direction="row"
    >
      <PageWrapper direction="column" gap={size === 'small' ? '16px' : '50px'}>
        <ContentContainer>
          <Image
            src="/interdev-branco.png"
            color="#fff"
            alt="Interdev Cloud Computing"
            style={{
              maxWidth: size === 'small' ? '120px' : '220px',
              cursor: 'pointer',
            }}
            onClick={onLogoClick}
          />
          <InfoContainer>
            <Box direction="column" align={size === 'small' ? 'center' : 'start'} justify="center">
             <Title>CONTATO</Title>
             <Description>webmaster@interdev.me</Description>
            </Box>
          </InfoContainer>
        </ContentContainer>
        <Divider />
        <Box direction="row">
          <Text color="#929a9f" size={size === 'small' ? '10px' : '14px'}>
            Interdev © 2024 - {t('desenvolvidoPor')}&nbsp;
          </Text>
          <Anchor
            href="https://www.interdev.me"
            target="_blank"
            color="#929a9f"
            size={size === 'small' ? '10px' : '14px'}
            label="Interdev Cloud Computing"
          />
        </Box>
      </PageWrapper>
    </Box>
  );
};

AppFooter.displayName = 'AppFooter';

export default AppFooter;
