import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../Interfaces/IUser';
import { Anchor } from 'grommet';

export interface ManageUsersTotalDocumentosProps {
  usuario: IUser;
}

const ManageUsersTotalDocumentos: React.FC<ManageUsersTotalDocumentosProps> = ({ usuario }): JSX.Element => {
  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    navigate(`/owner/manageUsuariosDocumentos/${usuario.Guid}`);
  };

  return (
    <Anchor
      title="Clique para ver os documentos do usuário"
      onClick={onDocumentosClick}
    >
      {usuario.TotalDocumentos || 0}
    </Anchor>
  );
};

ManageUsersTotalDocumentos.displayName = 'ManageUsersTotalDocumentos';

export default ManageUsersTotalDocumentos;
