import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import { Anchor, Box } from 'grommet';

export interface ManageProjetosTotalEquipamentosProps {
  projeto: IProjeto;
}

const ManageProjetosTotalEquipamentos: React.FC<ManageProjetosTotalEquipamentosProps> = ({ projeto }): JSX.Element => {
  const navigate = useNavigate();

  const onDocumentosClick = (): void => {
    navigate(`/admin/manageProjetosEquipamentos/${projeto.Guid}`);
  };

  // const onNavigateToAddDocumento = (): void => {
  //   navigate(`/admin/adicionarProjetosEquipamento/${projeto.Guid}`);
  // };

  return (
    <Box
      direction="row"
      gap="xmall"
      align="center"
    >
      <Anchor
        title="Clique para ver os equipamentos do projeto"
        onClick={onDocumentosClick}
      >
        {projeto.TotalEquipamentos || 0}
      </Anchor>
      {/* <Button
        title="Adicionar equipamento"
        icon={<Add size="16" />}
        onClick={onNavigateToAddDocumento}
      /> */}
    </Box>
  );
};

ManageProjetosTotalEquipamentos.displayName = 'ManageProjetosTotalEquipamentos';

export default ManageProjetosTotalEquipamentos;
