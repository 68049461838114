import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IVencimentos } from '../../../Interfaces/IVencimentos';
import getVencimentos from './Helpers/getVencimentos';
import Loading from '../Loading/Loading';
import VencimentosDocumentosEmpresa from './Components/DocumentosEmpresa/VencimentosDocumentosEmpresa';
import VencimentosDocumentosEquipamentos from './Components/DocumentosEquipamentos/VencimentosDocumentosEquipamentos';
import VencimentosDocumentosUsuarios from './Components/DocumentosUsuários/VencimentosDocumentosUsuarios';

export interface VencimentosProps {}

const Vencimentos: React.FC<VencimentosProps> = ({}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [vencimentos, setVencimentos] = useState<IVencimentos>({});

  const getData = async (): Promise<void> => {
    await getVencimentos()
      .then((data): void => {
        if (data.Success) {
          setVencimentos(data.Object!);
          setLoading(false);
        } else {
          toast.error('Erro ao buscar os vencimentos');
        }
      })
      .catch((): void => {
        toast.error('Erro ao buscar os vencimentos');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Vencimentos"
      />
      <Box
        width="xlarge"
        gap="small"
        direction="column"
      >
        {
          (loading) ? (<Loading />) : (
            <>
              <PageTitle
                Text="Documentos da Empresa"
                level={4}
              />
              <VencimentosDocumentosEmpresa
                documentos={vencimentos.DocumentosDaEmpresa || []}
              />
              <PageTitle
                Text="Documentos dos Equipamentos"
                level={4}
              />
              <VencimentosDocumentosEquipamentos
                documentos={vencimentos.DocumentosDoEquipamento || []}
              />
              <PageTitle
                Text="Documentos dos Usuários"
                level={4}
              />
              <VencimentosDocumentosUsuarios
                documentos={vencimentos.DocumentosDoUsuario || []}
              />
            </>
          )
        }
      </Box>
    </PageWrapper>
  );
};

Vencimentos.displayName = 'Vencimentos';

export default Vencimentos;
