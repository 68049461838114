import React from 'react';
import { Box, Text, Tip } from 'grommet';
import { StatusInfo } from 'grommet-icons';

export interface ManageProjetosDataPrevistaProps {
  data: string;
}

const ManageProjetosDataPrevista: React.FC<ManageProjetosDataPrevistaProps> = ({ data }): JSX.Element => {
  return (
    <Box
      direction="row"
      gap="small"
    >
      <Text truncate>{data}</Text>
      <Box>
        <Tip content="Previsão">
          <StatusInfo />
        </Tip>
      </Box>
    </Box>
  );
};

ManageProjetosDataPrevista.displayName = 'ManageProjetosDataPrevista';

export default ManageProjetosDataPrevista;
