import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import { IProjeto } from '../../../Interfaces/IProjeto';
import getProjetos from './Helpers/getProjetos';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import totalPages from '../Pagination/Helpers/totalPages';
import PageSize from '../Pagination/Components/PageSize';
import ManageProjetosDataTable from './Components/ManageProjetosDataTable';

export interface ManageProjetosProps {}

const ManageProjetos: React.FC<ManageProjetosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onAddEmpresaClick = (): void => {
    navigate('/admin/projetosAdicionar');
  };

  const [projetos, setProjetos] = useState<IPagination<IProjeto[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  // const setData = (data: IPagination<IProjeto[]>): void => {
  //   setProjetos(data);
  // };

  const initialPagination: IPagination<IProjeto[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getProjetos(pagination)
      .then((data): void => {
        if (data.Success) {
          setProjetos(data.Object!);
        } else {
          toast.error('Erro ao buscar empresas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar empresas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), projetos.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Projetos"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Projeto"
          title="Adicionar Projeto"
          onClick={onAddEmpresaClick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            {/* <PesquisarEmpresas
              refreshData={getData}
              setResults={setData}
            /> */}
            <Pagination
              page={projetos.Page!}
              last={totalPages(projetos.Total || 0, projetos.PageSize!)}
              onChange={updatePage}
            />
            <ManageProjetosDataTable
              projetos={projetos.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={projetos.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={projetos.Page!}
              last={totalPages(projetos.Total || 0, projetos.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManageProjetos.displayName = 'ManageProjetos';

export default ManageProjetos;
