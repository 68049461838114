import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button } from 'grommet';
import { Add, LinkPrevious } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IProjetoDocumento } from '../../../Interfaces/IProjetoDocumento';
import getDocumentosDoProjeto from './Helpers/getDocumentosDoProjeto';
import Loading from '../Loading/Loading';
import ProjetoRelatorioDocumentosTable from '../ManageProjetosRelatorio/Components/ProjetoRelatorioDocumentosTable';

export interface ManageProjetosDocumentosProps {}

const ManageProjetosDocumentos: React.FC<ManageProjetosDocumentosProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddDocumentoClick = (): void => {
    navigate(`/admin/adicionarDocumentoProjeto/${guid}`);
  };

  const onBackToProjetos = (): void => {
    navigate('/admin/projetos');
  };

  const [documentos, setDocumentos] = useState<IProjetoDocumento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async (): Promise<void> => {
    setLoading(true);

    await getDocumentosDoProjeto(guid!)
      .then((data): void => {
        if (data.Success) {
          setDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar documentos da empresa');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar documentos da empresas');
      });
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Documentos do Projeto"
      />
      <Box
        width="xlarge"
        align="end"
        direction="row"
        gap="xsmall"
        justify="end"
      >
        <Button
          primary
          label="Adicionar Documento"
          title="Adicionar Documento"
          onClick={onAddDocumentoClick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
              bottom: 'medium',
            }}
          >
            <ProjetoRelatorioDocumentosTable
              refreshData={getData}
              documentos={documentos}
              completo
            />
          </Box>
        )
      }
      <Box
        width="xlarge"
        align="center"
        direction="row"
        gap="xsmall"
        justify="center"
      >
        <Button
          primary
          label="Voltar"
          title="Voltar"
          onClick={onBackToProjetos}
          icon={<LinkPrevious />}
          reverse
        />
      </Box>
    </PageWrapper>
  );
};

ManageProjetosDocumentos.displayName = 'ManageProjetosDocumentos';

export default ManageProjetosDocumentos;
