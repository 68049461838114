import React from 'react';
import { DocumentImage, DocumentPdf } from 'grommet-icons';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { Button } from 'grommet';

export interface ManageEmpresasDocumentoDownloadProps {
  documento: IEmpresaDocumento;
}

const ManageEmpresasDocumentoDownload: React.FC<ManageEmpresasDocumentoDownloadProps> = ({ documento }): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (documento.Upload?.UploadMimeType === 'application/pdf') {
      return (
        <DocumentPdf />
      );
    }

    return (
      <DocumentImage />
    );
  };

  const onClick = (): void => {
    window.open(`${import.meta.env.VITE_API_URL}/api/Empresa/DownloadDocumentoEmpresa/${documento.Guid}.${documento.Upload?.UploadExtension}`, '_blank');
  };

  return (
    <Button
      icon={<Icon />}
      onClick={onClick}
    />
  );
};

ManageEmpresasDocumentoDownload.displayName = 'ManageEmpresasDocumentoDownload';

export default ManageEmpresasDocumentoDownload;
